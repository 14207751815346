.width100 {
  width: 100%;
}

.left {
  float: left;
}

.right {
  float: right;
}

.MuiPaper-root.standardCard {
  margin: 12px 24px 12px 0px;
  padding: 24px;
  border: 1px solid #e8e8e8;
  box-shadow: none;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.standardCard.quickLinks {
  padding: 24px 10px 0px 10px;
  flex-direction: row;
}

button.MuiButtonBase-root.quick-link-btn {
  border-radius: 25px;
  font-size: 16px;
  border: 1px solid #6b7280;
  background: transparent;
  box-shadow: none;
  float: right;
  color: #374151;
}

button.MuiButtonBase-root.quick-link-btn.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  border: none;
}

button.MuiButtonBase-root.quick-link-btn:hover {
  background: #e5e7eb;
}

.MuiTypography-h2.ap-home-page-heading {
  font-weight: 500;
  margin-top: 10px;
  font-size: 29px;
}

.MuiTypography-h2.margnT40 {
  margin-top: 40px;
}

button.standards-btn {
  border-radius: 25px;
  font-size: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  border: 1px solid #6b7280;
  background: transparent;
  box-shadow: none;
  color: #374151;
}

button.standards-btn:hover {
  background: #e5e7eb;
  box-shadow: none;
}

button.standards-btn.disabled {
  border: none;
}

button.see-plan-btn {
  flex: 1;
  margin-left: 5px;
  border-color: #e5e7eb;
}

.level-label {
  position: relative;
}

.level-label:before {
  content: "";
  height: 9px;
  width: 3px;
  position: absolute;
  left: -10px;
  top: 7px;
  border-radius: 4px;
}

.level-label.leading:before {
  background-color: #10b981;
}

.level-label.advanced:before {
  background-color: #40bef3;
}

.level-label.foundation:before {
  background-color: #fcd34d;
}

.sub-menu .active:before {
  content: "";
  height: 21px;
  width: 7px;
  position: absolute;
  left: 11px;
  border-radius: 4px;
}

.menu-closed .sub-menu .active:before {
  left: -6px;
  width: 5px;
}

.sub-menu .hiring.active:before {
  background-color: #ff8960;
}

.sub-menu.MuiList-root .hiring.active {
  color: #ff8960;
}

.sub-menu .performance.active:before {
  background-color: #baa3ff;
}

.sub-menu.MuiList-root .performance.active {
  color: #baa3ff;
}

.sub-menu .culture.active:before {
  background-color: #fde722;
}

.sub-menu.MuiList-root .culture.active {
  color: #fde722;
}

.sub-menu .leadership.active:before {
  background-color: #94d36d;
}

.sub-menu.MuiList-root .leadership.active {
  color: #94d36d;
}

.sub-menu .slt.active:before {
  background-color: #ff9900;
}

.sub-menu.MuiList-root .slt.active {
  color: #ff9900;
}

.sub-menu .flexible.active:before {
  background-color: #8aacd4;
}

.sub-menu.MuiList-root .flexible.active {
  color: #8aacd4;
}

.sub-menu .parental.active:before {
  background-color: #8ee4da;
}

.sub-menu.MuiList-root .parental.active {
  color: #8ee4da;
}

.sub-menu .transparent.active:before {
  background-color: #d3ff53;
}

.sub-menu.MuiList-root .transparent.active {
  color: #d3ff53;
}

.sub-menu .policies.active:before {
  background-color: #e67a94;
}

.sub-menu.MuiList-root .policies.active {
  color: #e67a94;
}

.sub-menu .respect.active:before {
  background-color: #8dddff;
}

.sub-menu.MuiList-root .respect.active {
  color: #8dddff;
}

.action-item .foundation {
  background-color: #fccb6a;
  padding: 1px 4px;
  border-radius: 4px;
}

.action-item .advanced {
  background-color: #baeafd;
  padding: 1px 4px;
  border-radius: 4px;
}

.action-item .leading {
  background-color: #a7f3d0;
  padding: 1px 4px;
  border-radius: 4px;
}

.insight-stat.MuiCardContent-root:last-child {
  padding: 0;
}

.MuiGrid-root .insight-card {
  border: 1px solid #f3f4f6;
  border-radius: 24px;
  box-shadow: none;
}

.MuiGrid-root .insight-card.flex3 {
  flex: 3;
}

.MuiGrid-root .insight-card.flexHalf {
  flex: 0.5;
}

.MuiGrid-root .insight-card.marR20 {
  margin-right: 20px;
}

.insight-card .insight-card-heading {
  font-weight: 600;
  border-bottom: 2px solid #f3f4f6;
  padding: 12px 24px;
}

.insight-card .insight-card-value {
  font-weight: 600;
  font-size: 29px;
  padding: 12px 24px;
}

.insight-card .insight-card-container {
  flex: 1;
  padding-bottom: 12px;
}

.insight-card .insight-card-secondary-heading {
  font-weight: 500;
  padding: 24px 24px 0px 24px;
}

.insight-card .borderRight {
  border-right: 1px solid #f3f4f6;
}

.benchmarking-container .chip {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.insight-card .benchmarking-container {
  padding: 0px;
}

.insight-card .benchmarking-container .benchmarking-text {
  padding: 12px 24px;
}

.insight-card .benchmarking-container .benchmarking-text .MuiTypography-body1 {
  font-size: 20px;
}

.recharts-tooltip-wrapper .custom-tooltip {
  background-color: #fff;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 13px;
  color: #191c1f;
}

.custom-tooltip .score.label {
  font-weight: 600;
}

.recharts-curve {
  stroke: transparent;
}
